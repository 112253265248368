// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/noise.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  color: var(--clr-primary);
  padding: 3rem var(--lateral-padding);
}
.Footer__Wrapper {
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  padding: 0 var(--lateral-padding);
}
.Footer__Navigation {
  font-weight: var(--fw-regular);
}`, "",{"version":3,"sources":["webpack://./views/components/footer/footer.scss"],"names":[],"mappings":"AAAA;EACE,yDAAkD;EAClD,yBAAyB;EACzB,oCAAoC;AACtC;AACA;EACE,WAAW;EACX,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,4BAA4B;EAC5B,iCAAiC;AACnC;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".Footer {\n  background-image: url(\"../../../images/noise.png\");\n  color: var(--clr-primary);\n  padding: 3rem var(--lateral-padding);\n}\n.Footer__Wrapper {\n  width: 100%;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n  height: var(--header-height);\n  padding: 0 var(--lateral-padding);\n}\n.Footer__Navigation {\n  font-weight: var(--fw-regular);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
