// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section {
  display: flex;
  position: relative;
}
.Section__Wrapper {
  width: 100%;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./views/components/section/section.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,UAAU;AACZ","sourcesContent":[".Section {\n  display: flex;\n  position: relative;\n}\n.Section__Wrapper {\n  width: 100%;\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
