// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hero {
  padding: 3rem var(--lateral-padding);
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.Hero__Content {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.Hero figure {
  border-radius: 50vw 50vw 0 0;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(#fff, #000);
          mask-image: radial-gradient(#fff, #000);
}
.Hero figure img {
  object-fit: cover;
}
@media (min-width: 1024px) {
  .Hero__Content > * {
    flex: 0 1 50%;
  }
  .Hero__Job {
    text-align: right;
  }
}`, "",{"version":3,"sources":["webpack://./views/components/hero/hero.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,+CAAuC;UAAvC,uCAAuC;AACzC;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".Hero {\n  padding: 3rem var(--lateral-padding);\n  display: flex;\n  flex-direction: column;\n  gap: 5rem;\n}\n.Hero__Content {\n  display: flex;\n  flex-direction: column;\n  gap: 5rem;\n}\n.Hero figure {\n  border-radius: 50vw 50vw 0 0;\n  overflow: hidden;\n  mask-image: radial-gradient(#fff, #000);\n}\n.Hero figure img {\n  object-fit: cover;\n}\n@screen lg {\n  .Hero__Content > * {\n    flex: 0 1 50%;\n  }\n  .Hero__Job {\n    text-align: right;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
